import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCheckCircle, MdCloudUpload, MdWarning, MdClose } from 'react-icons/md';
import { GrDocumentPdf, GrDocumentImage, GrDocumentConfig } from "react-icons/gr";
import apiService from '../../services/apiService';

const DocumentUpload = ({ doc, onFileUpload, onFileRemove, onDeleteFile, uploaded, process }) => {
    const [file, setFile] = useState(doc.file ? { ...doc } : null);
    const fileInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const onDrop = useCallback(acceptedFiles => {
        if (uploaded) return;

        const newFile = acceptedFiles[0];
        setFile({
            file: newFile,
            name: doc.name,
            description: doc.description,
            thumbnail: URL.createObjectURL(newFile),
            checked: true,
        });
        handleUploadClick(newFile);
    }, [doc, uploaded]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        noClick: uploaded,
        noKeyboard: uploaded,
        accept: 'image/*, application/pdf'
    });

    useEffect(() => {
        return () => {
            if (file && file.thumbnail) {
                URL.revokeObjectURL(file.thumbnail);
            }
        };
    }, [file]);

    const handleUploadClick = async (fileToUpload) => {
        if (!fileToUpload) return;

        const dataForm = new FormData();
        const processId = localStorage.getItem('processId');

        dataForm.append("docId", doc.id);
        dataForm.append("file", fileToUpload, fileToUpload.name);

        try {
            setIsUploading(true);
            const response = await apiService.uploadDocument(processId, dataForm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progress);
                },
            });

            setIsUploading(false);
            setFile(prevFile => ({ ...prevFile, uploaded: true }));
            onFileUpload && onFileUpload(response); // Notify parent component if required
            console.log('File uploaded successfully', response);
        } catch (error) {
            console.error('Error uploading file', error);
            setIsUploading(false);
            setProgress(0); // Reset progress on failure
        }
    };

    const getFileIcon = (file) => {
        const automaticDocuments = [
            "Mapa de Responsabilidades",
            "Comprovativo de Morada",
            "Último Modelo 3 do IRS"
        ];

        const isAutomatic = automaticDocuments.includes(doc.type);

        if (isAutomatic) {
            return (
                <div className="inline-flex items-center px-2 h-7 rounded-md text-sm text-10 font-bold bg-lime-300 text-lime-800 leading-none uppercase mr-8">
                    <GrDocumentConfig className="mr-2 text-lime-800" /> Obtido Automaticamente
                </div>
            );
        }

        if (!file || !file.type) {
            return null;
        }

        if (file.type.includes('pdf')) {
            return (
                <div className="inline-flex items-center px-2 h-7 rounded-md text-sm text-10 font-bold bg-fuchsia-300 text-fuchsia-800 leading-none uppercase mr-4">
                    <GrDocumentPdf className="mr-2 text-fuchsia-800" /> PDF
                </div>
            );
        }

        if (file.type.includes('image')) {
            return (
                <div className="inline-flex items-center px-2 h-7 rounded-md text-sm text-10 font-bold bg-fuchsia-300 text-fuchsia-800 leading-none uppercase mr-4">
                    <GrDocumentImage className="mr-2 text-fuchsia-800" /> IMAGEM
                </div>
            );
        }
        return null;
    };

    return (
        <div {...getRootProps()} className={`dropzone bg-[#616161]/5 p-6 mb-4 rounded-md ${uploaded ? 'dropzone--disabled' : ''} ${file ? 'bg-green/10' : ''}`}>
            <div className="flex justify-between">
                <p className="text-dark font-semibold">{doc.name}</p>
                {file ? <MdCheckCircle size={22} className="text-lime-500" /> : <MdWarning size={22} className="text-orange-500" />}
            </div>

            <p className="text-sm text-dark mb-4 md:pr-40 sm:pr-0">{doc.description}</p>
            <div
                className={
                    file
                        ? '' // When file is present, use an empty className
                        : `justify-between border-dashed border-2 rounded-md p-2 border-dark/15 md:flex sm:block grid md:grid-cols-3 sm:grid-cols-1 relative ${isDragActive ? 'bg-[#616161]/15' : ''
                        }`
                }
            >                <input {...getInputProps()} className="hidden" ref={fileInputRef} />

                {!file ? (
                    <p className="flex col-span-2 py-2 text-dark/40 font-semibold text-sm text-12">
                        {isDragActive ? 'Largue aqui o seu ficheiro...' : 'Arraste para aqui o seu ficheiro ou clique no botão para carregar documento'}
                    </p>
                ) : (
                    <></>
                    // <div className="md:flex sm:block col-span-2 py-2 text-dark font-semibold text-sm">
                    //     {getFileIcon(file)}
                    //     <p className="mr-8 content-center text-12">{file && file.file ? file.file.name : `${doc.name}.pdf`}</p>
                    // </div>
                )}

                {!doc.disabled && file && (
                    <></>
                    // <button
                    //     type="button"
                    //     onClick={(e) => {
                    //         e.stopPropagation();
                    //         e.preventDefault();
                    //         onDeleteFile(doc.id, doc.holder, doc.id_process);
                    //     }}
                    //     className="justify-self-end flex md:w-fit sm:w-[100%] bg-red-500 hover:bg-red-700 text-white font-normal py-2 px-4 rounded cursor-pointer text-sm"
                    // >
                    //     <MdClose size={20} className="mt-1" />
                    // </button>
                )}

                {!file && (
                    <button
                        type="button"
                        className="justify-self-end flex w-fit amco-blue hover:amco-blue/75 text-white font-normal py-2 px-4 rounded cursor-pointer text-sm"
                        onClick={() => fileInputRef.current.click()}
                        disabled={isUploading}
                    >
                        <MdCloudUpload size={20} className="mr-2" /> {isUploading ? `Uploading ${progress}%` : 'Carregar documento'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default DocumentUpload;